// Styles written by Jacob Bearce @ https://www.weblinxinc.com/

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-block {
    @media screen and (max-width: $l-break) {
        font-size: 1.5625vw;
    }
}

.header_inner {
    & {
        padding-bottom: remify($content-padding * 0.6);
        padding-top: remify($content-padding * 0.6);
    }
}

.header_logo {
    & {
        display: inline-block;
        text-align: left;
    }

    .logo_name {
        font-size: remify(56, 16);
        line-height: remify(64, 56);
    }

    .logo_tagline {
        align-items: center;
        display: flex;
        font-size: remify(16, 16);
        line-height: remify(16, 16);
    }

    .logo_tagline::before {
        background: currentColor;
        content: "\0020";
        display: block;
        flex: 1 1 0%;
        height: remify(1);
        margin: remify(0 10 0 0, 16);
        min-width: 0;
    }
}

.header_menu-list_container {
    .menu-list.-navigation {
        transform: translateX(#{remify(-18, 20)}) translateY(#{remify(8, 20)});
    }
}

/* fullbleed variant */

.header-block.-fullbleed {
    & {
        padding-bottom: remify($content-padding * 0.6);
        padding-top: remify($content-padding * 0.6);
    }

    > .header_inner {
        padding-bottom: 0;
        padding-top: 0;
    }
}
