// Styles written by Jacob Bearce @ https://www.weblinxinc.com/

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.content_inner {
    & {
        border-radius: remify($content-padding / 2);
        box-shadow: remify(0 0 6 0) transparentize($dark, (2 / 3));
        margin-bottom: remify($content-padding * 1.6);
        padding: remify(($content-padding * 1.5) ($content-padding * 2));
    }

    &.-hero {
        border-radius: 0;
        box-shadow: none;
        padding-bottom: remify($content-padding);
        padding-top: remify($content-padding * 2.5);
    }

    &.-hasbutton {
        padding-bottom: remify($content-padding * 2);
    }

    &:not(:last-child) {
        margin-bottom: remify($content-padding * 2);
    }
}

.content_button {
    & {
        border-radius: remify(100, 24);
        bottom: 0;
        left: auto;
        margin: 0;
        padding: remify(22.5 40, 24);
        position: absolute;
        right: remify($content-padding * 2.5);
        transform: translateY(50%);
    }

    .content_inner.-hero & {
        position: static;
        right: auto;
        transform: none;
    }
}

/* fullbleed variant */

.content-block.-fullbleed {
    & {
        padding-left: remify($content-padding);
        padding-right: remify($content-padding);
    }

    > .content_inner.-fullbleed {
        max-width: none;
    }
}
