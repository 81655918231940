// Styles written by Jacob Bearce @ https://www.weblinxinc.com/

/* ------------------------------------------------------------------------ *\
 * Text
\* ------------------------------------------------------------------------ */

.text {
    & {
        color: $foreground;
        font-display: swap;
        font-family: $body-font;
        font-range: $xs-break $xl-break;
        font-size: responsive remify(16, 16) remify(20, 16);
        font-weight: 400;
        line-height: remify(24, 16);
        margin: remify(0 0 24, 16);
    }
}

/* label variant */

.text.-label {
    & {
        cursor: pointer;
    }
}

/* list variant */

.text.-list {
    & {
        list-style: disc;
        padding: remify(0 0 0 24, 16);
    }

    .text_list-item {
        display: list-item;
    }
}

/* table variant */

.text.-table {
    & {
        border-collapse: collapse;
        display: table;
        width: 100%;
    }

    .text_header {
        display: table-header-group;
    }

    .text_body {
        display: table-row-group;
    }

    .text_footer {
        display: table-footer-group;
    }

    .text_row {
        display: table-row;
    }

    .text_cell {
        border-bottom: remify(1) solid darken($background, 15.2941);
        display: table-cell;
        padding: remify(12.8 9.6, 16);
        vertical-align: top;
    }

    .text_row:first-child > .text_cell {
        border-top: remify(1) solid darken($background, 15.2941);
    }

    .text_cell.-header {
        font-weight: 700;
    }
}
