// Styles written by Jacob Bearce @ https://www.weblinxinc.com/

/* ------------------------------------------------------------------------ *\
 * Link
\* ------------------------------------------------------------------------ */

.link {
    & {
        color: $accent;
        cursor: pointer;
        text-decoration: none;
        transition: color 0.15s;
    }

    &:focus,
    &:hover {
        color: $accent_alt;
    }
}
