// Styles written by Jacob Bearce @ https://www.weblinxinc.com/

/* ------------------------------------------------------------------------ *\
 * Logo
\* ------------------------------------------------------------------------ */

.logo {
    & {
        display: block;
        text-align: center;
        text-decoration: none;
    }
}

.logo_name {
    & {
        color: $accent;
        display: block;
        font-family: $heading-font;
        font-size: remify(27, 16);
        font-weight: 900;
        line-height: remify(31, 27);
        text-transform: uppercase;
        white-space: nowrap;
    }
}

.logo_tagline {
    & {
        color: $foreground;
        font-family: $body-font;
        font-size: remify(12, 16);
        font-weight: 400;
        line-height: remify(18, 12);
    }
}
