// Styles written by Jacob Bearce @ https://www.weblinxinc.com/

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer-block {
    & {
        @include layer("footer");
    }
}

.footer_inner {
    & {
        background: transparentize($page_background, 0.15);
        box-shadow: remify(0 0 6 0, 16) transparentize($dark, (2 / 3));
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(($content-padding * 1.25) $content-padding);
    }
}

.footer_text {
    @media screen and (max-width: $xs-break - (1 / 16)) {
        &.-table {
            display: block;
            text-align: center;
        }

        &.-table .text_body {
            display: block;
        }

        &.-table .text_row {
            display: block;
        }

        &.-table .text_cell {
            border: 0;
            display: block;
        }
    }
}

/* fullbleed variant */

.footer-block.-fullbleed {
    & {
        background: transparentize($page_background, 0.15);
        box-shadow: remify(0 0 6 0, 16) transparentize($dark, (2 / 3));
        padding: remify(($content-padding * 1.25) $content-padding);
    }

    > .footer_inner {
        background: none;
        box-shadow: none;
        padding: 0;
    }

    > .footer_inner.-fullbleed {
        max-width: none;
    }
}
