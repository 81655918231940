// Styles written by Jacob Bearce @ https://www.weblinxinc.com/

/* ------------------------------------------------------------------------ *\
 * Button
\* ------------------------------------------------------------------------ */

.button {
    & {
        background: $primary;
        border: 0;
        border-radius: remify(100, 18);
        box-shadow: remify(0 2 4 0, 18) transparentize($dark, 0.5);
        color: $light;
        cursor: pointer;
        display: inline-block;
        font-display: swap;
        font-family: $body-font;
        font-size: remify(18, 16);
        font-weight: 700;
        line-height: remify(22.5, 18);
        margin: remify(0 0 15, 18);
        outline: 0;
        padding: remify(16.875 30, 18);
        text-decoration: none;
        transition: background-color 0.15s;
    }

    &:focus,
    &:hover {
        background-color: $primary_alt;
    }
}
