// Styles written by Jacob Bearce @ https://www.weblinxinc.com/

/* ------------------------------------------------------------------------ *\
 * Alert Banner
\* ------------------------------------------------------------------------ */

.alert-banner_container {
    & {
        background: $warning_alt;
        padding: remify(($content-padding * 0.1) ($content-padding * 0.6));
    }

    .alert-banner {
        color: $light;
        margin: 0 auto;
        max-width: remify($site-width);
    }

    .link {
        color: $light;
        font-weight: 800;
    }
}
