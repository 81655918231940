// Styles written by Jacob Bearce @ https://www.weblinxinc.com/

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-block {
    & {
        @include layer("header");
    }
}

.header_inner {
    & {
        background: transparentize($background, 0.15);
        box-shadow: remify(0 0 6 0, 16) transparentize($dark, (2 / 3));
        left: 0;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(($content-padding * 0.4) ($content-padding * 0.6) ($content-padding * 0.9));
        position: absolute;
        right: 0;
        top: 0;
    }
}

.header_link {
    & {
        color: currentColor;
    }
}

.header_title {
    & {
        font-size: remify(20, 16);
        line-height: remify(23, 20);
        margin: remify(0 0 13, 20);
    }
}

.header_text {
    & {
        font-size: remify(16, 16);
        line-height: remify(22, 16);
        margin: remify(0 0 22, 16);
        padding: remify(0 0 0 24, 16);
        position: relative;
    }

    .header_icon {
        color: lighten($foreground, 41.9608);
        left: 0;
        position: absolute;
        top: 0;
    }
}

.header_menu-toggle {
    & {
        bottom: 0;
        left: 50%;
        position: absolute;
        transform: translateX(-50%) translateY(50%);
    }
}

/* fullbleed variant */

.header-block.-fullbleed {
    & {
        background: transparentize($background, 0.15);
        box-shadow: remify(0 0 6 0, 16) transparentize($dark, (2 / 3));
        left: 0;
        padding: remify(($content-padding * 0.4) ($content-padding * 0.6) ($content-padding * 0.9));
        position: absolute;
        right: 0;
        top: 0;
    }

    > .header_inner {
        background: none;
        box-shadow: none;
        left: auto;
        padding: 0;
        position: static;
        right: auto;
        top: auto;
    }

    > .header_inner.-fullbleed {
        max-width: none;
    }
}
