// Styles written by Jacob Bearce @ https://www.weblinxinc.com/

/* ------------------------------------------------------------------------ *\
 * Title
\* ------------------------------------------------------------------------ */

.title {
    & {
        color: $primary;
        font-display: swap;
        font-family: $heading-font;
        font-range: $xs-break $xl-break;
        font-size: responsive remify(26, 16) remify(50, 16);
        font-weight: 700;
        line-height: remify(37.5, 26);
        margin: remify(0 0 10, 26);
        text-transform: uppercase;
    }
}

/* divider variant */

.title.-divider {
    & {
        align-items: center;
        display: flex;
    }

    &::before,
    &::after {
        background: darken($background_alt, 10);
        content: "\0020";
        display: block;
        flex: 1 1 0%;
        height: remify(1);
    }

    &::before {
        margin-right: remify(16, 16);
    }

    &::after {
        margin-left: remify(16, 16);
    }
}
