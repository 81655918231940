// Styles written by Jacob Bearce @ https://www.weblinxinc.com/

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.content-block {
    & {
        @include layer("content");

        margin: remify(0 0 ($content-padding * 1.2));
    }
}

.content_inner {
    & {
        background: transparentize($background, 0.15);
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify($content-padding ($content-padding * 0.4));
        position: relative;
    }

    &.-hero {
        background: none;
    }

    &.-hasbutton {
        padding-bottom: remify($content-padding * 2);
    }
}

.content_button {
    & {
        border-radius: 0;
        bottom: 0;
        left: 0;
        margin: 0;
        padding: remify(25 30, 18);
        position: absolute;
        right: 0;
        text-align: center;
    }
}

/* fullbleed variant */

.content-block.-fullbleed {
    > .content_inner.-fullbleed {
        max-width: none;
    }
}
