// Styles written by Jacob Bearce @ https://www.weblinxinc.com/

/* ------------------------------------------------------------------------ *\
 * Page
\* ------------------------------------------------------------------------ */

html,
body {
    & {
        background: $page_background;
        // height: 100%;
        // width: 100%;
    }
}

body {
    background: url("../media/background-body.jpg") center top no-repeat fixed $page_background;
}

.page_container {
    & {
        min-height: 100%;
        overflow: hidden;
        padding-top: remify($content-padding * 4.3);
        position: relative;
        width: 100%;
    }
}
